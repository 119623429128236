@import "../../common/helpers/variables";
@import "../../common/mixins";
@import "../sprites/sp_card_item.scss";
@import "../svg/sp_common.scss";

.c-card-item {
    position: relative;
    background: #fff;
    line-height: 1.5;
    letter-spacing: 0;
    font-family: $font-family;

    > a {
        &:hover {
            text-decoration: none;
        }
    }

    .c-nation {
        margin-right: 6px;
        margin-bottom: 8px;

        .amazon {
            float: left;
            @include get-sprite-common("bi_amazon", 60);
            margin-top: 6px;
        }
    }

    .c-card-item__price-info {
        .c-card-item__price {
            del {
                font-size: 13px;
                line-height: 22px;
                color: #999;

                .value {
                    font-size: 16px;
                }
            }
        }
    }

    &--list {
        display: table;
        width: 100%;

        .c-card-item__thumb {
            display: table-cell;
            vertical-align: top;
        }

        .c-card-item__info {
            display: table-cell;
        }

        .c-review-event {
            float: left;
        }

        .c-starrate__review {
            margin: -1px 0 0 2px;
            color: #666;

            &:first-of-type {
                margin: -1px 0 0 0;
            }
        }
    }

    &.c-card-item--deal {
        box-sizing: border-box;
        overflow: hidden;
        width: 394px;
        border-radius: 4px;

        .c-card-item__thumb-img {
            display: inline-block;
            width: 100%;
            height: 197px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;

            img {
                transition: transform 0.2s ease-in;
            }
        }

        .c-card-item__thumb-emblem {
            top: 9px;
            left: 9px;

            img {
                width: 80px;
                height: 80px;
            }
        }

        .c-card-item__info {
            position: relative;
            min-height: 214px;
            padding: 12px 0 13px 20px;
            box-sizing: border-box;

            .c-nation {
                float: left;
            }

            .c-time-flag {
                float: left;
                margin: 0 0 10px 0;
            }

            .c-flag {
                float: left;
                margin-bottom: 8px;
            }

            .c-card-item__name {
                clear: both;
                height: 1.5em;
                margin: 0 20px 27px 0;
                overflow: hidden;
                font-size: 15px;
                color: #111;
            }
            .c-card-item__name-addition {
                margin-top: -26px;
            }

            .c-card-item__counsel {
                margin: -5px 0 0 20px;
                color: $color-11st-blue;
                font-size: 20px;
                font-weight: normal;
            }

            .c-starrate {
                position: relative;
                margin: 13px 0 0 0;

                dd {
                    float: left;
                }
                .c-starrate__review {
                    margin: -1px 0 0 2px;
                    color: #666;

                    .sr-only {
                        @include sr-only;
                    }
                }
            }

            .c-card-item__benefit {
                position: relative;
                margin: 5px 0 0 0;
                height: 18px;

                .benefit {
                    padding: 0 0 0 12px;

                    &:before {
                        position: absolute;
                        left: 6px;
                        top: 9px;
                        width: 2px;
                        height: 2px;
                        border-radius: 50%;
                        background: #ccc;
                        content: "";
                    }
                }
                dt + .benefit {
                    padding-left: 0;

                    &::before {
                        display: none;
                    }
                }
            }

            .c-card-item__deal-guide {
                color: #666;
            }

            .card_addition {
                height: 53px;
                margin: 0 21px;
                padding: 15px 0 0 0;
                border-top: 1px solid #f4f4f4;

                .c_prd_coupon {
                    margin-left: 12px;
                }

                .c-card-item__deal {
                    padding: 0;
                    border: none;

                    &:after {
                        display: none;
                    }
                }
            }

            .c-card-item__price-info {
                @include clearfix;
                padding: 0;

                .c-card-item__rate {
                    font-size: 16px;
                    line-height: 22px;
                    margin-right: 8px;

                    .value {
                        display: inline-block;
                        margin: 0 0 0 0;
                        font-size: 24px;
                        line-height: 24px;
                    }

                    .c-card-item__special {
                        display: inline-block;
                        margin: 2px 0 0 0;
                        font-size: 18px;
                        line-height: 22px;
                    }
                }

                .c-card-item__price {
                    float: left;
                    font-size: 15px;
                    line-height: 22px;
                    margin-right: 8px;

                    .value {
                        display: inline-block;
                        margin: 0 0 1px 0;
                        font-size: 24px;
                        line-height: 24px;
                    }

                    del {
                        font-size: 13px;
                        line-height: 22px;
                        color: #999;

                        .value {
                            font-size: 16px;
                        }
                    }
                    .c-card-item__detail {
                        margin-top: 11px;
                    }
                }

                .c-card-item__price-del {
                    float: left;
                    margin: 2px 0 0 0;
                    color: #949494;
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }

        .c-card-item__delivery {
            @include clearfix;
            clear: both;
            padding: 6px 0 0 0;

            dt {
                @include skip;
            }

            dd {
                position: relative;
                float: left;
                line-height: 20px;
            }

            dt + dd,
            dt + .delivery {
                padding-left: 0;

                &::before {
                    display: none;
                }
            }

            .delivery {
                padding: 0 0 0 14px;

                &::before {
                    position: absolute;
                    left: 2px;
                    top: 9px;
                }
            }

            .allclub {
                font-size: 13px;
                color: #666;

                &:before {
                    @include logo-svg($logo-allclub, 40px, 16px);
                    display: inline-block;
                    margin: -2px 0px 1px 0;
                    vertical-align: middle;
                    content: "";
                }
            }

            .sktpass {
                font-size: 13px;
                color: #666;
                line-height: 20px;

                &:before {
                    @include get-sprite-common("bi_sktpass", 56);
                    display: block;
                    float: left;
                    margin: 3px 4px 0 0;
                    line-height: 20px;
                    content: "";
                }

                & + .delivery {
                    clear: none;

                    &::before {
                        display: block;
                        position: absolute;
                        left: 2px;
                        top: 9px;
                    }
                }
            }

            .value {
                font-family: $font-family-number-lato;
            }
        }

        .c-card-item__bottom-info,
        .c-card-item__extra {
            .c-card-item__delivery {
                padding-top: 0;
            }
            .c-relates {
                height: 50px;
                padding: 15px 0;
                box-sizing: border-box;
                border-top: none;
            }
            .c-relates__button-link {
                padding: 0;
            }
            .c-card-item__relates {
                height: 50px;
                padding: 15px 0;
                box-sizing: border-box;
            }
            .c-card-item__text {
                height: 50px;
                padding: 15px 0;
                line-height: 20px;
                box-sizing: border-box;
            }
            .c-card-item__deal {
                height: 50px;
                padding: 15px 0;
                box-sizing: border-box;
            }
        }

        .c-card-item__bottom-info {
            position: relative;

            .c-card-item__delivery {
                position: relative;
                height: 50px;
                padding: 15px 0 15px;
                line-height: 20px;
                box-sizing: border-box;

                $c-card-deal-shooting-icon: (
                    delivery: (
                        55,
                        bi_shooting,
                    ),
                    install: (
                        53,
                        bi_shooting_install,
                    ),
                    plus: (
                        85,
                        bi_shooting_plus,
                    ),
                    fresh: (
                        55,
                        bi_shooting_fresh,
                    ),
                    seller: (
                        55,
                        bi_shooting_seller,
                    ),
                    freeze: (
                        55,
                        bi_shooting_freeze,
                    ),
                );

                //.shooting-delivery, .shooting-install
                @each $iconKey, $iconValue in $c-card-deal-shooting-icon {
                    .shooting-#{$iconKey} {
                        float: left;
                        margin: 5px 0 0 0;

                        &::before {
                            @include get-sprite-common(#{nth($iconValue, 2)}, nth($iconValue, 1));
                            margin-top: -1px;
                            display: block;
                            content: "";
                        }

                        & + .delivery {
                            padding-left: 4px;

                            em {
                                margin-right: 4px;
                            }

                            &::before {
                                display: none;
                            }
                        }
                    }
                }

                $c-card-delivery-icon: (
                    ssg-emart: (
                        49,
                        bi_ssg_emart_compact,
                    ),
                    ssg-dawn: (
                        49,
                        bi_ssg_dawn_compact,
                    ),

                    freshtable: (
                        55,
                        bi_freshtable,
                    ),
                );

                //.delivery-type
                @each $iconKey, $iconValue in $c-card-delivery-icon {
                    .#{$iconKey} {
                        float: left;
                        margin-right: 2px;
                        font-family: $font-family;
                        font-size: 13px;
                        line-height: 19px;
                        min-height: 19px;

                        strong {
                            float: left;
                            @include get-sprite-common(#{nth($iconValue, 2)}, nth($iconValue, 1));
                            overflow: hidden;
                            line-height: 200;
                            margin-top: 4px;
                        }
                    }
                }
            }

            .c-card-item__qty {
                bottom: 15px;
                right: 0;
            }

            &:not(:first-child) {
                border-top: 1px solid #f4f4f4;
            }
        }
    }

    &.c-card-item--collection {
        box-sizing: border-box;
        width: 224px;
        height: 100%;
        border-radius: 4px;

        &.c-card-item--space {
            padding-bottom: 52px;

            .c-card-item__info {
                .c-starrate {
                    margin: 0;
                }
            }
            .c-relates {
                position: absolute;
                left: 20px;
                right: 20px;
                bottom: 0;
            }
        }

        .c-card-item__thumb {
            padding: 10px 10px 0 10px;
        }

        .c-card-item__thumb-img {
            width: 204px;
            height: 204px;

            img {
                transition: transform 0.2s ease-in;
            }
        }

        .c-card-item__info {
            @include clearfix;
            padding: 17px 0 17px 20px;

            .c-flag {
                margin: 0 0 8px 0;
            }

            .c-card-item__name {
                max-height: 3em;
                padding: 0 20px 0 0;
                margin: 0 0 8px 0;
            }

            .c-card-item__price-info {
                @include clearfix;
                margin: 0 0 11px 0;
                padding: 0;

                .c-card-item__rate {
                    margin-right: 8px;
                    font-size: 16px;
                    line-height: 22px;

                    > .value {
                        display: inline-block;
                        margin: 0 0 2px 0;
                        font-size: 22px;
                        line-height: 22px;
                    }
                }

                .c-card-item__price {
                    > .value {
                        font-size: 22px;
                        font-weight: bold;
                        line-height: 22px;
                    }
                }
            }

            .c-starrate {
                margin: 0 0 4px 0;
                line-height: 18px;

                dd {
                    float: left;
                }
                .sr-only {
                    @include sr-only;
                }
            }

            .c-card-item__counsel {
                display: block;
                margin: 0 0 12px 0;
                font-size: 13px;
                color: $color-grayscale-04;
                font-weight: normal;

                em {
                    display: block;
                    font-size: 16px;
                    margin: 0 0 2px 0;
                    line-height: 22px;
                    color: $color-11st-blue;
                }
            }

            .c-starrate__review {
                margin: -1px 0 0 2px;
                color: #666;
            }

            .c-card-item__delivery {
                position: relative;

                .sktpass {
                    &:before {
                        float: left;
                    }
                }
            }
        }

        .c-card-item__save {
            display: block;
            width: 100%;
            height: 52px;
            line-height: 52px;
            text-align: center;
            font-size: 17px;
            color: $color-11st-red;

            &:before {
                @include sprite-retina($sp_card_item_cart);
                display: inline-block;
                margin: 12px 6px 0 0;
                vertical-align: top;
                overflow: hidden;
                line-height: 200px;
                content: "";
            }
        }

        .c-card-item__addition {
            .c-cart-button {
                position: absolute;
                bottom: 0;
                clear: both;
                border-top: 1px solid #eee;
            }
        }
    }

    &.c-card-item--gallery {
        box-sizing: border-box;
        height: 100%;
        padding: 10px 10px 20px 10px;
        border-radius: 4px;
        box-sizing: border-box;

        &.c-card-item--space {
            padding-bottom: 52px;

            .c-card-item__info {
                .c-card-item__name {
                    margin-bottom: 11px;
                }
            }
            .c-card-item__addition {
                position: absolute;
                left: 20px;
                right: 20px;
                bottom: 0px;
                margin: 0;
                padding: 15px 0;
                border-top: 1px solid #f4f4f4;

                dt {
                    @include sr-only();
                }

                .c-card-item__deal {
                    padding: 0;
                    border: 0;

                    &::before {
                        @include sprite-retina($sp_card_item_hot_deal_2);
                        margin-right: 3px;
                    }
                    &::after {
                        display: none;
                    }
                }
            }
            .c-relates {
                position: absolute;
                left: 20px;
                right: 20px;
                bottom: 0;
            }
        }

        .c-card-item__thumb-img {
            img {
                transition: transform 0.2s ease-in;
            }
        }

        .c-card-item__swatch {
            margin: 20px 0 -8px 0;
        }

        .c-card-item__info {
            @include clearfix;
            padding: 16px 0 0 10px;

            .c-time-flag {
                float: left;
            }

            .c-flag {
                float: left;
                margin: 0 0 8px 0;
            }

            .c-flag-box {
                margin-bottom: 8px;

                .c-flag {
                    margin-bottom: 0;
                }
            }

            .c-card-item__name {
                clear: both;
                max-height: 3em;
                padding: 0 10px 0 0;
                margin: 0 0 7px 0;
            }

            .c-starrate {
                margin: 0 0 2px 0;
                line-height: 18px;

                dd:first-of-type {
                    .c-starrate__review {
                        .sr-only {
                            @include skip-reset();
                        }
                    }
                }

                dd.c-starrate__review {
                    &:nth-of-type(1) {
                        margin-left: 0;
                        .sr-only {
                            @include skip-reset();
                            color: $color-gray-04;
                        }
                    }
                }

                .c-starrate__review {
                    margin: -1px 0 0 2px;
                }

                .c-review-event ~ .c-starrate__sati {
                    margin-top: 3px;
                }
                .c-review-event ~ .c-starrate__review {
                    margin-top: 0;
                }

                &--simply {
                    .c-starrate__review {
                        margin: 0 0 0 2px;
                    }
                    .c-review-event ~ .c-starrate__review {
                        margin-top: 2px;
                    }
                }
            }

            .c-card-item__counsel {
                display: block;
                margin: 0 0 12px 0;
                font-size: 13px;
                color: $color-grayscale-04;
                font-weight: normal;

                em {
                    display: block;
                    font-size: 16px;
                    margin: 0 0 2px 0;
                    line-height: 22px;
                    color: $color-11st-blue;
                }
            }

            .c-card-item__benefit {
                .benefit {
                    float: none;
                }
            }

            .c-card-item__delivery {
                .delivery {
                    &::before {
                        display: none;
                    }
                }
                .sktpass {
                    @include clearfix;
                    margin: 0 0 2px 0;

                    &::before {
                        float: left;
                    }
                }

                [class^="ssg-"] {
                    clear: none;
                    float: left;
                    & + [class^="ssg-"] {
                        margin-left: 2px;
                    }
                }
            }
        }

        .c-card-item__price-info {
            @include clearfix;
            margin: 0 0 12px 0;
            padding: 0;
        }

        .c-card-item__lowest {
            margin-bottom: 0;
        }

        .c-card-item__rate {
            margin-right: 8px;
            font-size: 14px;
            line-height: 24px;

            .value {
                display: inline-block;
                margin: 0;
                font-size: 14px;
                line-height: 24px;
                vertical-align: top;
            }
        }

        .c-card-item__price {
            > .value {
                font-size: 22px;
                line-height: 22px;
            }
        }

        .c-card-item__price-del {
            float: left;
            color: #949494;
            font-size: 13px;
            line-height: 22px;

            del {
                font-family: $font-family-number-lato;
                font-size: 14px;
            }
        }

        .c-card-item__special {
            font-size: 13px;
        }

        .c-card-item__price {
            clear: both;
            display: block;
            margin: 0 0 1px 0;
            font-size: 15px;
            line-height: 22px;

            del {
                font-size: 13px;
                line-height: 22px;
                color: #999;

                .value {
                    font-size: 16px;
                }
            }
        }

        .c-card-item__addition {
            margin: 7px 0 0 10px;

            .c-cart-button {
                clear: both;
                border-top: 1px solid #eee;
            }
        }

        .c-card-item__deal {
            position: relative;
            height: 30px;
            padding: 4px 20px 0 6px;
            box-sizing: border-box;
            color: #333;

            &::before {
                margin: -1px 2px 1px 0;
            }
            &::after {
                position: absolute;
                right: 9px;
                top: 10px;
                margin: 0;
            }
        }
    }

    &.c-card-item--box {
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28);

        &:hover {
            box-shadow: 0px 6px 32px -8px rgba(0, 0, 0, 0.2), 0px 0px 1px 0px rgba(0, 0, 0, 0.7);

            .c-card-item__thumb-img {
                img {
                    transform: scale(1.08);
                }
            }
        }
    }

    &.c-card-item--zzim {
        .c-card-item__info {
            .c-flag,
            .c-card-item__mart,
            .c-card-item__name {
                margin-right: 35px;
            }
        }
        .c-card-item__button {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            margin-top: 100%;

            .c-like-button {
                left: auto;
                top: 0;
                right: 20px;
                z-index: 5;
                margin-top: 5px;
            }
        }
    }

    &.c-card-item--soldout {
        .c-card-item__info {
            .c-card-item__counsel {
                em {
                    color: $color-grayscale-04;
                }
            }
        }
    }

    .c-flag-box {
        & + .c-card-item__brand {
            margin-top: 2px;
        }
    }

    &-badge {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 0;
        z-index: 2;

        &__status {
            display: inline-block;
            padding: 3px 8px;
            background-color: #666;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            color: #fff;
            letter-spacing: 0;
            vertical-align: top;

            &--active {
                background-color: $color-11st-red;
            }
        }

        &__time {
            display: inline-block;
            padding: 3px 8px;
            background-color: #fafafa;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #333;
            letter-spacing: 1px;
            vertical-align: top;
        }
    }

    &__badge-text {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
    }

    &__brand {
        @include clearfix();
        margin: 6px 0 2px;
        color: $color-gray-02;
        font-family: $font-family-lato;
        font-size: 14px;
        font-weight: 900;
        line-height: 17px;

        dd {
            @include clearfix();
        }

        &:first-of-type {
            margin-top: 0;
        }
    }

    &__brand-flag {
        &:first-child {
            margin: 0 4px 0 0;
        }
        margin-left: 4px;
        @include get-sprite-common("ic_official", 41);
        overflow: hidden;
        line-height: 200px;
    }

    &__brand-name {
        display: inline-block;
        @include text-ellipsis();
    }

    &__floting {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    &__recommend {
        height: 40px;
        padding: 7px 10px;
        border-radius: 4px;
        border: solid 1px #ccc;
        background-color: #fff;
        color: $color-gray-02;
        font-size: 15px;

        &::after {
            @include ico-angle(down, 6px, 1px, $color-gray-03, static);
            display: inline-block;
            margin: -7px 0 0 4px;
            content: "";
        }

        &[aria-expanded="true"] {
            &::after {
                @include ico-angle(up, 6px, 1px, $color-gray-03, static);
                display: inline-block;
                margin: 0px 0 0 4px;
                content: "";
            }
        }
    }

    &__option {
        @include clearfix;
        @include text-ellipsis-multiple(2);
        margin: 6px 0;
        max-height: 34px;
        color: $color-11st-bluegray;
        line-height: 17px;
        font-family: $font-family-lato;
        font-size: 14px;

        dd {
            display: inline;

            &:not(:first-of-type) {
                &::before {
                    display: inline;
                    content: " / ";
                }
            }
        }
    }

    &__name-flag {
        color: $color-11st-blue;
        margin-right: 4px;
    }
}

.c-card-item__rank {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 32px;
    padding: 0 11px;
    height: 32px;
    background: rgba(255, 247, 248, 0.9);
    border: solid 1px rgba(255, 0, 56, 0.1);
    box-sizing: border-box;
    color: $color-11st-red;
    font-family: $font-family-number-lato;
    font-weight: bold;
    text-align: center;
    line-height: 32px;
    letter-spacing: 0.5px;
    z-index: 10;

    &.c-card-item__rank--highlight {
        height: 32px;
        line-height: 32px;
        background: rgba(244, 49, 66, 0.8);
        border: solid 1px rgba(244, 49, 66, 0.1);
        color: $color-gray-14;
        text-align: center;
    }

    &.c-card-item__rank--impact {
        padding-left: 12px;
        text-align: left;
        font-size: 19px;
        line-height: 45px;
        @include sprite-retina($sp_card_item_flag_ranking);
        color: $color-gray-14;
        background-color: transparent;
        border: none;
    }
}

.c-card-item__thumb {
    position: relative;
    text-align: center;

    a {
        display: block;
        width: 100%;
        height: 100%;
    }

    .c-card-item__thumb-img {
        display: inline-block;
        overflow: hidden;
        position: relative;
        vertical-align: top;
        width: 100%;
        height: 100%;

        img {
            @include img-center;
        }
    }

    .c-card-item__thumb-emblem {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;

        img {
            width: 50px;
            height: 50px;
        }
    }

    .c-emblem {
        position: absolute;
        top: 12px;
        left: 12px;
        z-index: 10;
    }
}

.c-card-item__soldout-text {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    overflow: hidden;
    line-height: 700px;
    z-index: 20;

    &::before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 180px;
        height: 180px;
        background: url("/img/common/v3/soldout.png") no-repeat;
        background-size: 180px 180px;
        content: "";
    }
}

.c-card-item__info {
    dt {
        @include skip();
    }
    // 마트상품
    .c-card-item__mart {
        font-size: 13px;
        font-weight: bold;
        line-height: 23px;
        letter-spacing: 0;

        a {
            color: inherit;

            &:hover {
                text-decoration: none;
            }
        }

        &.c-card-item__mart--emart {
            color: rgb(255, 177, 0);
        }

        &.c-card-item__mart--homeplus {
            color: rgb(244, 49, 66);
        }

        &.c-card-item__mart--gsfresh {
            color: #81c443;
        }

        &.c-card-item__mart--lottemart {
            color: #e11b22;
        }

        .c-card-item__mart-rank {
            display: inline-block;
            margin: -2px 5px 0 0;
            width: 23px;
            height: 23px;
            color: #fff;
            font-family: $font-family-number-lato;
            font-size: 12px;
            background-image: linear-gradient(to bottom, rgba(255, 49, 49, 0.6), rgba(255, 49, 49, 0.6)), linear-gradient(306deg, #ff408b 96%, #ff8e3c 16%);
            line-height: 23px;
            text-align: center;
            vertical-align: middle;
        }
    }

    // 상품명
    .c-card-item__name {
        display: block;
        color: $color-gray-02;
        word-break: break-all;
        font-size: 15px;
        overflow: hidden;
        @include clearfix();

        a {
            color: $color-gray-02;
        }
    }

    .c-card-item__deal-guide {
        margin: 0 0 12px 0;
        font-size: 15px;
        margin-top: 10px;
        color: $color-11st-red;
        font-size: 14px;
        line-height: 17px;
    }

    .c-card-item__name-addition {
        overflow: hidden;
        margin: 0 20px 7px 0;
        color: $color-11st-blue;
        font-size: 13px;
        height: 1.5em;
        word-break: break-all;
    }

    .c-card-item__counsel {
        color: #111;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
    }

    .c-card-item__rate {
        float: left;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0;
        color: $color-11st-red;
        font-family: $font-family-number-lato;

        .value {
            font-size: 20px;
            line-height: 20px;
            vertical-align: bottom;
        }
    }

    .c-card-item__special {
        font-size: 16px;
    }

    .c-card-item__rental {
        font-size: 16px;
        color: $color-11st-blue;
    }

    .c-card-item__price {
        color: #111;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0;

        .value {
            font-family: $font-family-number-lato;
            vertical-align: bottom;
        }

        > .value {
            font-size: 20px;
            font-weight: bold;
            line-height: 20px;
        }

        del {
            color: #666;
            font-size: 12px;
            line-height: 16px;

            .value {
                font-size: 14px;
            }
        }

        .c-card-item__price-per {
            color: #999;
        }

        .c-card-item__price-lowest {
            color: $color-11st-red;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: 0;

            .sr-only {
                @include skip-reset();
                font-size: 15px;
                line-height: 18px;
            }

            .value {
                font-size: 20px;
                font-family: $font-family-number-lato;
                font-weight: bold;
                line-height: 20px;
                vertical-align: bottom;
            }
        }

        &.c-card-item__price--point {
            color: $color-11st-red;
        }

        .c-card-item__detail {
            display: block;
            margin: 7px 0 0 0;
            color: #666;
            font-size: 13px;
        }
    }

    .c-card-item__price-per {
        color: $color-gray-06;
        font-family: $font-family-lato;
    }

    .c-card-item__price-del {
        color: $color-gray-06;
    }

    .c-card-item__lowest {
        color: $color-11st-red;
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 3px;
        @include clearfix();

        &-text {
            float: left;
            margin-right: 4px;
        }

        &-value {
            float: left;
            position: relative;
            padding-left: 9px;
            margin-right: 2px;

            &::before {
                position: absolute;
                top: 11px;
                left: 0;
                @include ico-triangle("down", 8px, 5px, #ff0038, "absolute");
                content: "";
            }
        }

        .c-iconinfo {
            &--help {
                float: left;
                margin-top: 2.5px;
                @include size(15px);

                &:after {
                    @include get-sprite-common("ic_help", 15);
                }
            }
        }

        .c_layer_expand {
            z-index: 10;

            .c-iconinfo--help {
                float: none;
                margin-top: -2px;
            }
        }

        .c_layer_item {
            width: 294px;
            padding-right: 54px;
            color: $color-grayscale-03;
            font-size: 15px;
            line-height: 22px;
        }
    }

    .c-card-item__benefit {
        overflow: hidden;
        color: #666;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 18px;

        .benefit {
            float: left;
            position: relative;
            font-size: 13px;
        }

        .value {
            color: $color-11st-blue;
            font-family: $font-family-number-lato;
        }
    }

    .c-card-item__advertise {
        width: 100%;
        color: $color-11st-green;
        font-size: 13px;
        line-height: 18px;
        @include text-ellipsis();
    }

    .c-card-item__store {
        color: #666;
        font-size: 13px;
        line-height: 18px;

        .office {
            color: $color-11st-bluegray;
        }
    }

    .c-card-item__compare {
        vertical-align: middle;

        .compare {
            color: #111;
            font-size: 13px;
            line-height: 22px;
            vertical-align: middle;
        }

        .count {
            color: $color-11st-blue;

            .value {
                font-family: $font-family-number-lato;
            }
        }

        //Beta 버튼
        .beta {
            display: inline-block;
            overflow: hidden;
            line-height: 200px;
            @include sprite-retina($sp_card_item_btn_beta);

            .info {
                display: inline-block;
                line-height: 120px;
                vertical-align: middle;
            }
        }

        &.active {
            .c_prd_layer {
                display: block;
                z-index: 10;
            }
        }

        &-text {
            color: $color-gray-04;
            font-weight: normal;
            font-size: 13px;
            line-height: 19px;

            dt {
                float: left;
                position: static !important;
                width: auto !important;
                height: auto !important;
                margin: 0 2px 0 0 !important;
                clip: auto !important;
                white-space: normal !important;
                color: $color-gray-04;
                font-weight: normal;
                font-size: 13px;
                line-height: 19px;
            }
            dd {
                float: left;
            }

            @include clearfix();
        }
    }
}

.c-card-item__delivery {
    color: #666;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;

    .value {
        font-family: $font-family-number-lato;
    }

    em {
        color: $color-11st-blue;
    }

    $c-card-shooting-icon: (
        delivery: (
            55,
            bi_shooting,
        ),
        install: (
            53,
            bi_shooting_install,
        ),
        plus: (
            85,
            bi_shooting_plus,
        ),
        fresh: (
            55,
            bi_shooting_fresh,
        ),
        seller: (
            56,
            bi_shooting_seller,
        ),
        freeze: (
            55,
            bi_shooting_freeze,
        ),
    );

    //.shooting-delivery, .shooting-install
    @each $iconKey, $iconValue in $c-card-shooting-icon {
        .shooting-#{$iconKey} {
            float: left;
            margin-right: 2px;
            font-family: $font-family;
            font-size: 13px;
            line-height: 19px;
            min-height: 19px;

            strong {
                float: left;
                @include get-sprite-common(#{nth($iconValue, 2)}, nth($iconValue, 1));
                overflow: hidden;
                line-height: 200;
                margin-top: 4px;
            }

            em {
                float: left;
                margin-left: 4px;
                color: $color-11st-blue;
                font-weight: normal;

                &::before {
                    display: none;
                }
            }

            .c-card-item__text {
                display: block;
                font-family: $font-family-lato;
                color: $color-gray-04;
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
            }
        }
    }

    $c-card-delivery-icon: (
        ssg-emart: (
            49,
            bi_ssg_emart_compact,
        ),
        ssg-dawn: (
            49,
            bi_ssg_dawn_compact,
        ),
        freshtable: (
            55,
            bi_freshtable,
        ),
    );

    //.delivery-type
    @each $iconKey, $iconValue in $c-card-delivery-icon {
        .#{$iconKey} {
            float: left;
            margin-right: 2px;
            font-family: $font-family;
            font-size: 13px;
            line-height: 19px;
            min-height: 19px;

            strong {
                float: left;
                @include get-sprite-common(#{nth($iconValue, 2)}, nth($iconValue, 1));
                overflow: hidden;
                line-height: 200;
                margin-top: 4px;
            }

            em {
                float: left;
                margin-left: 4px;
                color: $color-11st-blue;
                font-weight: normal;

                &::before {
                    display: none;
                }
            }

            .c-card-item__text {
                display: block;
                font-family: $font-family-lato;
                color: $color-gray-04;
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
            }
        }
    }

    .delivery {
        &:not(:first-of-type)::before {
            width: 2px;
            height: 2px;
            background-color: #ccc;
            display: inline-block;
            margin: 0 2px 0 4px;
            vertical-align: middle;
            border-radius: 100%;
            content: "";
        }

        .c-card-item__point {
            color: $color-11st-blue;
        }
    }

    .allclub {
        display: inline-block;
        font-size: 13px;
        color: #666;
        line-height: 1.5;

        .c-card-item__point {
            color: $color-11st-red;
        }

        .c-card-item__text {
            display: inline-block;
        }

        &:before {
            @include logo-svg($logo-allclub, 40px, 16px);
            display: inline-block;
            margin: -2px 0px 1px 0;
            vertical-align: middle;
            content: "";
        }
    }

    .sktpass {
        display: inline-block;
        font-size: 13px;
        color: #666;
        line-height: 18px;

        .c-card-item__point {
            color: $color-11st-blue;
            font-size: 13px;
        }

        .c-card-item__text {
            display: inline-block;
        }

        &:before {
            @include get-sprite-common("bi_sktpass", 56);
            display: inline-block;
            margin: 1px 4px 0 0;
            vertical-align: top;
            content: "";
        }

        & + .delivery {
            clear: both;

            &::before {
                display: none;
            }

            font-family: $font-family-lato;
        }
    }

    @include clearfix();
}

.c-card-item__deal {
    display: inline-block;
    padding: 7px 10px 6px 6px;
    border: 1px solid #eee;
    font-size: 13px;
    color: #666;
    box-sizing: border-box;

    .c-card-item__point {
        color: $color-11st-red;

        .value {
            font-family: $font-family-number-lato;
        }
    }

    &::before {
        @include sprite-retina($sp_card_item_hot_deal);
        display: inline-block;
        margin: -3px 2px 1px 0;
        vertical-align: middle;
        content: "";
    }

    &::after {
        width: 7px;
        height: 7px;
        border: 1px solid #999;
        border-width: 1px 1px 0 0;
        transform: rotate(45deg);
        display: inline-block;
        margin: -2px 0 1px 2px;
        vertical-align: middle;
        content: "";
    }
}

.c-card-item__cart {
    @include clearfix;

    .c-card-item__amount {
        float: left;
        position: relative;
        width: calc(100% - 40px);
        height: 40px;
        border: 1px solid #eee;
        box-sizing: border-box;
        vertical-align: middle;

        .c-card-item__quantity {
            display: block;
            width: auto;
            height: 100%;
            border: none;
            padding: 0 40px;
            border: 0;
            color: #111;
            font-size: 16px;
            font-family: $font-family-number-lato;
            line-height: 40px;
            background: none;
            text-align: center;
        }

        button {
            position: absolute;
            top: 0;
            bottom: 0;
            display: inline-block;
            width: 40px;
            line-height: 200px;
            overflow: hidden;
            background-color: #fff;
        }

        .c-card-item__add {
            right: 0;
            border-left: 1px solid #eee;

            &::after {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 50%;
                height: 2px;
                background-color: #999;
                margin-top: -1px;
                margin-left: calc(-25% + 2px);
                content: "";
            }

            &::before {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 2px;
                height: 50%;
                background-color: #999;
                margin-top: -25%;
                content: "";
            }
        }

        .c-card-item__remove {
            left: 0;
            border-right: 1px solid #eee;

            &::after {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 50%;
                height: 2px;
                background-color: #999;
                margin-top: -1px;
                margin-left: calc(-25% + 2px);
                content: "";
            }
        }
    }

    .c-card-item__save {
        float: right;
        overflow: hidden;
        position: relative;
        width: 40px;
        height: 40px;
        line-height: 200px;
        border: 1px solid #eee;
        border-left: none;

        //motion resouce
        &:before {
            content: "";
            position: absolute;
            background-color: $color-11st-red;
            width: 4px;
            height: 4px;
            left: 50%;
            top: -5px;
        }

        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            @include get-sprite-common("ic_cart", 18);
            transform: translate(-50%, -50%);
        }
    }

    .c-card-item__link {
        position: relative;
        display: block;
        height: 40px;
        padding-left: 16px;
        color: #333;
        border: 1px solid #eee;
        font-size: 15px;
        line-height: 38px;

        &:hover {
            text-decoration: none;
        }

        &::after {
            position: absolute;
            right: 16px;
            top: 15px;
            content: "";
            @include ico-angle("right", 8px, 1px, #999, "absolute");
        }
    }

    &--border-top {
        border-top: 1px solid $color-gray-12;

        .c-card-item__amount {
            border: none;
            .c-card-item__remove,
            .c-card-item__add {
                border: none;
            }
        }
        .c-card-item__save {
            border: none;
            border-left: 1px solid $color-gray-12;
        }
    }
}

.c-card-item__compare-button {
    button {
        position: relative;
        min-width: 108px;
        height: 29px;
        padding: 0 12px;
        border-radius: 14.5px;
        border: solid 1px #eee;
        background-color: #fff;
        color: #111;
        font-size: 11px;
        letter-spacing: 0;
        line-height: 26px;
        overflow: hidden;
        vertical-align: bottom;
        text-align: center;
    }

    .count {
        display: inline-block;
        font-weight: bold;
        color: #111;
    }

    .beta {
        display: inline-block;
        @include sprite-retina($sp_card_item_beta);
        line-height: 200px;
        margin: 5px 0 0 1px;
        vertical-align: top;
    }
}

.c-card-item__lowest-button {
    display: block;
    min-width: 183px;
    height: 40px;
    border-radius: 20px;
    border: solid 1px #ccc;
    background-color: #fff;
    box-sizing: border-box;
    color: #333;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 37px;
    text-align: center;
    overflow: hidden;

    &:after {
        display: inline-block;
        margin: -3px 0 0 5px;
        vertical-align: middle;
        @include ico-angle("right", 6px, 1px, $color-11st-blue, "static");
    }
}

.c-card-item__addition {
    .c-cart-button {
        display: block;
        width: 100%;
        height: 52px;
        line-height: 52px;
        text-align: center;
        font-size: 17px;
        color: $color-11st-red;

        &:before {
            @include sprite-retina($sp_card_item_cart_28);
            display: inline-block;
            margin: -2px 6px 0 0;
            vertical-align: middle;
            overflow: hidden;
            line-height: 200px;
            content: "";
        }
    }
}

.c-card-item__button {
    .c-like-button {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.c-card-item__extra {
    //height: 50px;
    //padding: 14px 0;
    margin: 0 21px;
    border-top: 1px solid #f4f4f4;
    box-sizing: border-box;

    dt {
        @include sr-only();
    }

    .c-card-item__deal {
        padding: 0;
        border: 0;

        &::after {
            display: none;
        }
    }

    .c-card-item__bottom-info {
        .c-card-item__text {
            font-size: 13px;
        }
    }
}

.c-card-item__qty {
    position: absolute;
    right: 20px;
    bottom: 16px;
    font-size: 13px;
    color: $color-grayscale-04;
    font-family: $font-family-lato;
}

.c-relates {
    border-top: 1px solid #eee;

    &__button-link {
        display: block;
        padding: 15px 0 17px;
        color: #333;
        font-size: 13px;

        &:hover {
            text-decoration: none;
        }
    }

    &__icon {
        display: inline-block;
        height: 18px;
        vertical-align: middle;
        margin-top: -2px;

        img {
            height: 100%;
        }

        &--lg {
            width: 20px;
            height: 20px;
            margin-top: -2.5px;
        }
    }
}

.c-card-item__swatch {
    margin: 8px 0 4px;
    font-size: 0;
    text-align: center;

    dl {
        display: flex;
        justify-content: center;
    }

    &-item {
        display: flex;
        justify-content: center;
    }

    .c-card-item__thumb {
        width: 26px;
        height: 26px;
        margin-right: 4px;
        overflow: hidden;
        position: relative;
        border-radius: 100%;

        img {
            @include img-center();
        }
        &:after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px solid #ddd;
            border-radius: 100%;
            box-sizing: border-box;
            content: "";
        }
    }

    .c-card-item__swatch-number {
        margin-left: 2px;
        font-size: 12px;
        color: #999;
        line-height: 26px;
        vertical-align: top;

        .value {
            font-family: $font-family-number-lato;
        }
    }
}

.c-card-item__color {
    margin-top: 8px;

    dt {
        @include sr-only();
    }

    dd {
        display: flex;
        justify-content: center;
        cursor: pointer;

        span {
            overflow: hidden;
            width: 26px;
            height: 26px;
            margin: 0 5px 0 0;
            border: 1px solid $color-gray-10;
            border-radius: 50%;
            font-size: 7px;
            color: transparent;
            line-height: 200px;
            box-sizing: border-box;
        }
    }

    &-number {
        margin-left: 1px;
        font-size: 12px;
        color: $color-gray-07;
        line-height: 26px;

        .value {
            font-family: $font-family-number-lato;
        }
    }
}

.c-card-item__anchor {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.c-card-item__bundle {
    overflow: hidden;

    > dd {
        margin-left: -13px;

        > dl {
            float: left;
            position: relative;
            padding-left: 13px;
            z-index: 2;

            &::before {
                position: absolute;
                top: 3.5px;
                left: 6px;
                display: block;
                width: 1px;
                height: 12px;
                background-color: $color-gray-11;
                content: "";
            }
        }
    }
}

.c-card-item__price-special {
    width: 100%;
    color: $color-11st-red;
    font-size: 14px;
    line-height: 21px;

    strong {
        font-weight: normal;
    }
}

.c-card-item__discount {
    font-family: $font-family-lato;
    &--point {
        color: $color-11st-red;
    }

    .value {
        font-weight: 700;
    }
}
